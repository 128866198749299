<template>

    <iframe :src="src" ref="iframe" class="iframe"></iframe>
    <div @click="sendMessage">向iframe发送信息</div>
</template>

<script>
export default {
  name: "WorkFlow",
  data () {
    var baseUrl = this.$sapi.baseUrl;
    var workNum = '100001';
    return {
      src: baseUrl + 'workflow/todo?workNum=' + workNum,
      iframeWin: {}
    }
  },
  methods: {
    sendMessage () {
      // 外部vue向iframe内部传数据
      this.iframeWin.postMessage({
        cmd: 'getFormJson',
        params: {}
      }, '*')
    },
  },
  mounted () {
    // 在外部vue的window上添加postMessage的监听，并且绑定处理函数handleMessage
    window.addEventListener('message', this.handleMessage)
    this.iframeWin = this.$refs.iframe.contentWindow
  },
  handleMessage (event) {
    // 根据上面制定的结构来解析iframe内部发回来的数据
    const data = event.data
    switch (data.cmd) {
      case 'returnFormJson':
        // 业务逻辑
        break
      case 'returnHeight':
        // 业务逻辑
        break
    }
  }
}
</script>

<style scoped>
.iframe {
position: absolute;
  left: 0;

  right: 0;

  top: 40px;

  bottom: 40px;

  width: 99%;

  height: 94%;

  background: #fff;

  overflow-y: hidden;
}

</style>